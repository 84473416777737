export enum ResourceType {

    PROJECT = 'project',
    PROJECTS = 'projects',
    RUN = 'run',
    RUNS = 'runs',
    PLANNED_RUN = 'plannedRun',
    DATASET = 'dataset',
    DATASETS = 'datasets',
    FASTQ_DATASETS = 'fastqs',
    OTHER_DATASETS = 'datasets',
    BIO_SAMPLE = 'biosample',
    BIO_SAMPLES = 'biosamples',
    SAMPLE = 'sample',
    SAMPLES = 'samples',
    ANALYSIS = 'analyses',
    LIBRARIES = 'libraries',
    APP_SESSION = 'appSession',
    CMS = 'cms',
    REQUEUE = 'requeues', // Biosampl Lab requeue sub resource types

    // This looks wrong but is deliberate as the route for viewing run lanes is 'summary' which is what we're matching to.
    // If in the future, you want to change this to LANES = 'lanes' you'll need to add a redirect for run metrics route
    LANES = 'summary',
    SAMPLE_FILE = 'SampleFile' 
}
