import { ResourceType } from '../../core/model/resource-type';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export class ModalTexts {

    static readonly EMAIL = 'Email address';
    static readonly CANCEL_BUTTON_TEXT = 'Cancel';
    static readonly OPTIONAL_MESSAGE_LABEL = 'Optional Message';
    static readonly OPTIONAL_MESSAGE_PLACEHOLDER = 'Add a personal message (optional)';
    static readonly ASSOCIATE_RESOURCE_MESSAGE = `<span>Share the associated Project(s) as well (<strong>Recommended</strong>)<br />
    Unchecking this box will limit what the receipient will have access to.</span>`;

    static readonly SHARE = {
        MODAL_TITLE: 'Share',
        GET_LINK_TITLE: 'Share By Link',
        INVITE_COLLABORATOR: 'Invite a collaborator',
        COLLABORATORS: 'Collaborators',
        CONFIRM_BUTTON_TEXT: 'Save Settings',
        READ_ONLY: 'Read Only',
        READ_WRITE: 'Read and Write',
        WRITE: 'Write',
        SHARE_ERROR: 'Something went wrong, Couldn\'t save share settings',
        ARCHIVE_ERROR: 'This resource has been archived, and the sharing functionality is not available at this time',
        GENERIC_LINK_ERROR_MESSAGE: 'An error occured while generating the share link.',
        DEACTIVATE_ERROR_MESSAGE: 'An error occured while deactivating the share link.',
        ACTIVATE_TEXT: 'Click \'Activate\' to generate a new URL for sharing.',
        COPY_URL_TEXT: 'Copy the below URL and send it to anyone you want to share with.',
        ALERT_MESSAGE_DEACTIVATION(resourceType: ResourceType): string {
            return 'Are you sure you want to deactivate this share link? This link will no longer provide access to the '
                + resourceType.toString() + '.' + ' To manage users who already have access to this data via sharing,' +
                ' select Share and then Send Invitation from the action toolbar.';
        },
        RESOURCE_FILES_IN_ICA_ALERT_SHARE_OWNER: (resourceType: string) => `<p>This ${resourceType} has files in ICA. `
                                                                + `Sharing grants the recipient(s) access in Sequence Hub only.</p>`,
        RESOURCE_FILES_IN_ICA_ALERT_SHARE_RECIPIENT: (resourceType: string) => `The accepted ${resourceType} share has files in ICA. `
                                                                                + `Access has been granted in Sequence Hub only.`,
    };

    static readonly ARCHIVE = {
        CONFIRM_BUTTON_TEXT: 'Archive Data',
        MODAL_TITLE: 'Archive Data',
        ACTION_MESSAGE: `<span>
        Archiving data disables access and reduces storage costs. While data is archived some functionality is restricted,
        including downloading, sharing, and transferring. Archived data can be restored, which re-enables all functions.
        <br />
        <br />
        Depending on the size of the data, restoration may take a few days to complete. For more information, see the
        BaseSpace Sequence Hub online help.
        </span>`,
        ALERT_MESSAGE: {
            RUN: `<span>The indicated run(s) can not be archived for the following possible reasons:
            <ul>
                <li>You are not the owner or do not have permission to archive</li>
                <li>The run is not in a terminal state</li>
                <li>The run is already archived, is in the trash, or has been files-only deleted</li>
            </ul>
            </span>`,

            DATASET: `<span>The indicated dataset(s) can not be archived for the following possible reasons:
            <ul>
                <li>You are not the owner or do not have permission to archive</li>
                <li>The dataset is already archived, is in the trash, or has been files-only deleted</li>
                <li>A share or transfer operation is in progress for this dataset</li>
            </ul>
            </span>`
        },
        ERROR: 'Something went wrong while archiving data.',
        TOAST_SUCCESS: {
            RUN: 'Successfully archived $SUCCESS_COUNT run(s).',
            DATASET: 'Successfully archived $SUCCESS_COUNT dataset(s).'
        }
    };

    static readonly RESTORE = {
        CONFIRM_BUTTON_TEXT: 'Restore Data',
        MODAL_TITLE: 'Restore Data',
        ACTION_MESSAGE: `<span>
        Archiving data disables access and reduces storage costs. While data is archived some functionality is restricted,
        including downloading, sharing, and transferring. Archived data can be restored, which re-enables all functions.
        <br />
        <br />
        Depending on the size of the data, restoration may take a few days to complete. For more information, see the
        BaseSpace Sequence Hub online help.
        </span>`,
        ALERT_MESSAGE: {
            RUN: `<span>The indicated run(s) can not be restored for the following possible reasons:
            <ul>
                <li>You are not the owner or do not have permission to restore</li>
                <li>The run is not archived or an archive or restore operation is already in progress</li>
            </ul>
            </span>`,
            DATASET: `<span>The indicated dataset(s) can not be restored for the following possible reasons:
            <ul>
                <li>You are not the owner or do not have permission to restore</li>
                <li>The dataset is not archived or an archive or restore operation is already in progress</li>
            </ul>
            </span>`
        },
        ERROR: 'Something went wrong while restoring data.',
        TOAST_SUCCESS: {
            RUN: 'Successfully restored $SUCCESS_COUNT run(s).',
            DATASET: 'Successfully restored $SUCCESS_COUNT dataset(s).'
        }
    };

    static readonly EDIT_INFO = {
        CONFIRM_BUTTON_TEXT: 'Save',
        ERROR: 'Something went wrong while editing data.',
        MAX_LENGTH_DESCRIPTION_WARNING_MESSAGE: 'Project description cannot be greater than 500 characters',
        MAX_LENGTH_WARNING_MESSAGE: (maxLength, resourceType) => {
            switch (resourceType) {
                case ResourceType.RUN:
                  return `Name must contain less than ${maxLength} character`;
                case ResourceType.BIO_SAMPLE:
                  return `Alphanumeric, dashes, and underlines only, up to ${maxLength} characters max`;
                case ResourceType.PROJECT:
                  return  `Project name must contain less than ${maxLength} characters`;
                case ResourceType.ANALYSIS:
                  return `Analysis Name must contain less than ${maxLength} characters`;
                default:
                  return null;
            }
        },
        MANDATORY_FIELD_ERROR_MESSAGE: (resourceType) => {
            switch (resourceType) {
                case ResourceType.RUN:
                  return 'A name is required for this Run.';
                case ResourceType.BIO_SAMPLE:
                  return 'A name is required for this Biosample.';
                case ResourceType.PROJECT:
                  return 'A name is required for this Project.';
                case ResourceType.ANALYSIS:
                  return 'A name is required for this Analysis.';
                default:
                  return null;
            }
        },
    };

    static readonly CREATE_RESOURCE = {
        CONFIRM_BUTTON_TEXT: 'Save',
        ERROR: 'Something went wrong while saving data.',
        MAX_LENGTH_WARNING_MESSAGE: {
            PROJECT: 'Project name must contain less than $MAX characters'// replace $MAX accordingly
        },
        MANDATORY_FIELD_ERROR_MESSAGE: {
            PROJECT: 'A name is required for this project.'
        }
    };

    static readonly CHANGE_LANE_STATUS = {
        MODAL_TITLE: {
            QC: {
                LANE: 'Change Lane Status',
                ANALYSIS: 'TODO',
                FASTQ_DATASETS: 'TODO',
            },
            DELIEVRY: {
                ANALYSIS: 'TODO'
            }
        },
        CONFIRM_BUTTON_TEXT: 'Save',
        STATUS_NOT_SAME_ERROR_MESSAGE: 'All items must have the identical status in order to change them at the same time.',
        ERROR: 'Something went wrong while changing status.'
    };

    static readonly CHANGE_STATUS = {
        MODAL_TITLE: {
            QC : {
                LANE: 'Change Lane Status',
                ANALYSIS: 'Change Analysis Status',
                FASTQ_DATASETS: 'Change FASTQ QC Status',
            },
            DELIEVRY : {
                ANALYSIS: 'Change Delivery Status'
            }
        },
        CONFIRM_BUTTON_TEXT: 'Save',
        STATUS_NOT_SAME_ERROR_MESSAGE: 'All items must have the identical status in order to change them at the same time.',
        ERROR: 'Something went wrong while changing status.'
    };

    static readonly TRANSFER_OWNERSHIP = {
        CANCEL_BUTTON_TEXT: 'Cancel',
        CONFIRM_BUTTON_TEXT: 'Transfer',
        MODAL_TITLE: 'Transfer Ownership',
        INPUT_LABEL: 'Email ID',
        ALERT_MESSAGE: {
            RUN_WARNING: '<p>Transferring a run transfers ownership of the run only. ' +
                'Projects and associated data are transferred separately.</p><br>' +
                '<p>Completed transfers confer legal ownership of the data to the recipient and cannot be revoked. ' +
                'Make sure that you are authorized to make this transfer before proceeding.</p>',
            PROJECT_WARNING: '<p>Apps in your account that currently use this data may not function properly after the\
                    data is transferred out of your account. Please review the \
                <a href=\"/agreements/current/details?category=USER\" target=\"_blank\">BaseSpace User Terms of Use</a>\
                for more information.</p>',
            NULLIFY_BIOSAMPLE_DEFAULT_PROJECT_ALERT: '<p>This project is the default project for one or more Biosamples. ' +
                'Transferring this project will remove the default project association. Certain apps may not '+
                'function properly when using Biosamples with no default project.</p>'
        },
        RUN_STATUS_ERROR: 'Only completed or failed runs may be transferred. This run is ',
        SEND: {
            RESOURCE_SIZE_ERROR: (resourceType: ResourceType) => `${capitalizeFirstLetter(resourceType)} transfer timed out because the ${resourceType} is too large.
            You can try again later or reduce the size of the ${resourceType}.`
        },
        Accept: {
            RESOURCE_SIZE_ERROR: (resourceType: string) => `${capitalizeFirstLetter(resourceType)} transfer timed out because the ${resourceType} is too large.
            You can try again later or ask the ${resourceType} owner to reduce the size of the ${resourceType}.`
        }
    };

    static readonly FILE_PREVIEW = {
        CANCEL_BUTTON_TEXT: 'Cancel',
        CONFIRM_BUTTON_TEXT: 'Download',
    };

    static readonly TRASH_PLANNED_RUNS = {
        MODAL_TITLE: (plannedRunText) => `Move ${plannedRunText} to Trash`,
        LIST_NAME_COLUMN_HEADER: 'Planned Run',
        LIST_VALUE_COLUMN_HEADER: 'Status',
        MODAL_INFO_TEXT: (plannedRunText) => `This permanently deletes the ${plannedRunText} and it cannot be restored`,
        CANCEL_BUTTON_TEXT: 'Cancel',
        CONFIRMATION_BUTTON_TEXT: (isPluralize) => isPluralize ? 'Delete Runs' : 'Delete Run',
    };

    static readonly CANCEL_OWNERSHIP_TRANSFER = {
        MODAL_TITLE: 'Transfer Ownership Pending',
        CONFIRM_BUTTON_TEXT: 'Cancel Transfer',
        RUN_MESSAGE: 'This run is pending transfer of ownership.',
        PROJECT_MESSAGE: 'This project is pending transfer of ownership.',
        TRANSFER_ALREADY_ACCEPTED: 'Transfer cannot be canceled, because it was already accepted',
        ERROR_LOADING_INVITE: 'INTERNAL ERROR: unable to load invite'
    };

    static readonly CANCEL_ANALYSIS = {
        MODAL_TITLE: 'Cancel Analysis',
        CONFIRM_BUTTON_TEXT: 'Stop',
        CANCEL_ANALYSIS_MESSAGE: 'Do you want to cancel the selected analysis?',
        ERROR: 'Something went wrong while cancelling analysis.',

    };

    static readonly DOWNLOAD = {
        CONFIRM_BUTTON_TEXT: 'Download',
        BASESPACE_DOWNLOADER_MESSAGE: `BaseSpace Sequence Hub Downloader must be installed. ` +
            `It's a one-time installation and provides fast and secure downloads via SSL.`,
        BASESPACE_DOWNLOADER_LINK_TEXT: 'INSTALL DOWNLOADER',
        RUN_ANALYSIS_FILES_AVAILABLE: 'Analysis files including VCF and BAM can be downloaded from the Analysis page',
        RUN_ANALYSIS_FILES_NOT_AVAILABLE: 'No BCL files are available for this run.',
        DATASETS_ALERT_MESSAGE: 'This dataset has been moved to the trash and will not be downloaded.'
    };

    static readonly TRASH = {
        ERROR: 'Something went wrong while trashing.',
        CONFIRM_BUTTON_TEXT: 'Move to Trash',
        RUN_DELETE_OPTION_HEADER: 'Select the run file types to move to trash',
        DATA_FILES_ONLY: 'Data files Only',
        DATA_FILES_AND_RUN_RECORD: 'Data files and run record',
        ALL_RUN_RELATED_FILES: 'All Run-Related Files',
        ANALYSIS_ASSOCIATED_DATASETS_ALERT: 'Deleting an analysis deletes the associated datasets.',
        ANALYSIS_ASSOCIATED_SAMPLES_ALERT: 'Deleting an analysis deletes the associated samples.',
        ANALYSIS_ALERT: 'Apps in your account that currently use this analysis may not function properly after ' +
            'this analysis is deleted',
        ANALYSES_ALERT: 'Apps in your account that currently use these analyses may not function properly after ' +
        'these analyses are deleted.',
        SAMPLES_ALERT: 'Apps in your account that currently use these samples may not function properly after ' +
            'these samples are deleted',
        ALERT_MESSAGE: 'Please review the \
            <a href=\"/agreements/current/details?category=USER\" target=\"_blank\">BaseSpace User Terms of Use</a>\
            for more information.'
        ,
        DELETE_OPTION_HEADER: 'Select the file types to move to trash',
        ALL_RELATED_FILES: 'All Files'
    };

    static readonly UPLOAD_BIOSAMPLE_WORKFLOW = {
        MODAL_TITLE: 'Upload a BioSample Workflow',
        ERROR: 'Something went wrong while uploading Biosample workflow.',
        CSV_FORMAT_TEXT : 'The CSV format (also known as the "comma separated value" format) is a standard file type that' +
        'can be created using your favorite spreadsheet application, like Excel. The CSV format is' +
        'required in order to propertly import biosamples.',
        ANALYSIS_WORKFLOW_TEXT : 'Analysis workflows are packaged templates of Basespace Sequence Hub Apps with predefined' +
        'settings and QC thresholds to support automation of running analyses.',
        BIOSAMPLE_WORKFLOW_TEXT: 'The biosample workflow file is a comma-delimited file that is used to add biosamples in bulk',
        VALIDATION_ERROR: (TotalErrors: string, TotalWarnings: string) => '<div>' +
            '<span>' +
            'We found ' +
            `<span class="error-text" style="color: #de3439;">${TotalErrors} errors</span>` + ' and ' +
            `<span class="warning-text" style="color: #f5b500;">${TotalWarnings} warnings</span>` +
            ' in your document.' +
            '</span>' +
            '<span> Please correct the problems and upload again.</span>' +
            '</div>',
        VALIDATION_SUCCESS_MSG: '<div>' +
            '<span>We didn\'t find any errors in your document.</span>' +
            '<span> If everything looks good select the continue button to complete the uploading of your document.</span>' +
            '</div>'
    };

    static readonly REQUEUE_LIBRARY = {
        MODAL_TITLE: 'Requeue Library',
        CONFIRM_BUTTON_TEXT: 'Save',
        CANCEL_BUTTON_TEXT: 'Close'
    };

    static readonly REQUEUE_BIOSAMPLE = {
        MODAL_TITLE : 'Requeue Biosample',
        CONFIRM_BUTTON_TEXT : 'Save',
        NO_ACTIVE_PREP_REQUEST: 'To requeue a biosample, its prep request must be \
                                <strong>Active</strong> or <strong>MissingYield</strong>.',
        CANCEL_BIOSAMPLE_STATUS: 'To requeue a biosample, the associated biosample\'s \
                    status must be <strong>Active</strong> or <strong>Missing Yield</strong>.',
        UPDATE_YIELD_MESSAGE: 'We have calculated a new requested yield based upon\
                    the kit you selected. The required yield has also been updated.'
    }

    static readonly BIO_SAMPLE_UNLOCK = {
        CONFIRM_BUTTON_TEXT: 'Continue',
        MODAL_TITLE: 'Unlock Alert'
    };

    static readonly CHOOSER = {
        ERROR: 'Something went wrong.',
        CONFIRM_BUTTON_TEXT: 'Select',
        NEW_BUTTON_TEXT: 'New',
        ERROR_CREATING_NEW_RESOURCE: (resourceType: string) => `Error while creating a new ${resourceType}`
    };

    static readonly BIOSAMPLE_DEFAULT_PROJECT_CHOOSER = {
        TITLE: 'Select a Default Project for Biosample(s)',
        SEARCH_PLACEHOLDER: 'Search Projects...'
    };

    static readonly BIOSAMPLE_CHOOSER  = {
        TITLE: 'Select Biosample',
        SEARCH_PLACEHOLDER: 'Search Biosamples...'
    };

    static readonly SAMPLE_CHOOSER  = {
        TITLE: 'Select Sample',
        SEARCH_PLACEHOLDER: 'Search Samples...'
    };

  static readonly ANALYSIS_CONFIGURATION_DEFAULT_PROJECT_CHOOSER = {
    TITLE: 'Select Project',
    SEARCH_PLACEHOLDER: 'Search Projects...'
  };

    static readonly COPY_DATASET_PROJECT_CHOOSER = {
        TITLETOOLTIP: 'Associated biosamples and app sessions will also be copied to the new location'
    };

    static readonly DELETE_LIBRARY_PREP_KIT = {
        MODAL_TITLE: 'Delete Library Prep Kit',
        CONFIRM_BUTTON_TEXT: 'Delete',
        CLOSE_BUTTON_TEXT: 'Cancel',
        ALERT_MESSAGE: 'Are you sure you want to delete this Library Prep Kit?<br/>'+
        "You can't undo this action",
        TOAST_ERROR: 'Something went wrong while deleting Library Prep Kit.',
    };

    static readonly DELETE_INDEX_ADAPTER_KIT = {
        MODAL_TITLE: 'Delete Index Adapter Kit',
        CONFIRM_BUTTON_TEXT: 'Delete',
        CLOSE_BUTTON_TEXT: 'Cancel',
        ALERT_MESSAGE: 'Are you sure you want to delete this Index Adapter Kit?<br/>'+
        "You can't undo this action",
        TOAST_ERROR: 'Something went wrong while deleting Index Adapter Kit.',
    };

    static readonly DELETE_CONFIG = {
        MODAL_TITLE: 'Delete Configuration?',
        CONFIRM_BUTTON_TEXT: 'Yes, delete',
        CLOSE_BUTTON_TEXT: 'No, cancel'
    };

    static readonly RESOURCE_IN_ICA_MESSAGE = {
        EDIT_ANALYSES_MESSAGE: 'Updating the Analysis in Sequence Hub does not update the corresponding Analysis in ICA.'
    };

    static readonly FILE_CHOOSER = {
        TITLE: 'Select a File',
        SEARCH_PLACEHOLDER: 'Search Files...'
    };
}
