// The status of the sequencing run, from Prep Tab planning to FASTQ analysis.
// Statuses are automatically updated from the instrument.
export enum RunStatus {
    // Prep Tab statuses

    // The run is being planned in the Prep Tab.
    PLANNING = 'Planning',

    // The run prepared on the Prep Tab is ready to be sequenced.
    READY = 'Ready',

    // The status of the run is unknown.
    UNKNOWN = 'Unknown',

    // Sequencing statuses

    // The instrument is sequencing the run.
    RUNNING = 'Running',

    // A user paused the run on the instrument control software.
    PAUSED = 'Paused',

    // The instrument has been put into a Stopped state through the instrument control software.
    STOPPED = 'Stopped',

    // File Upload and Analysis statuses

    // The instrument completed sequencing and is uploading files.
    UPLOADING = 'Uploading',

    // The instrument failed to upload files to the BaseSpace Sequence Hub.
    UPLOAD_FAILED = 'Upload Failed',

    // The file has uploaded completely and is waiting for the Generate FASTQ analysis to begin.
    PENDING_ANALYSIS = 'Pending Analysis',

    // The Generate FASTQ analysis is running.
    ANALYZING = 'Analyzing',

    // The sequencing run and subsequent Generate FASTQ analysis completed successfully.
    COMPLETE = 'Complete',

    // Either the sequencing run was failed using the instrument control software or Generate FASTQ failed to complete.
    FAILED = 'Failed',

    // A flow cell has been sent back to a cBot for rehybing. When the new run is complete, the rehybing status is
    // changed to Failed.
    REHYBING = 'Rehybed',

    // There is an issue with the sample sheet associated with the run. The run can be requeued after
    // the sample sheet is fixed.
    NEEDS_ATTENTION = 'Needs Attention',

    // There is an issue with the sample sheet associated with the run.
    // The run can be requeued after the sample sheet is fixed.
    TIME_OUT = 'Timed Out'
}

export const  NON_SUCCESS_RUN_STATUSES = [RunStatus.FAILED.toString()];

export const REQUEUE_SUPPORTED_RUN_STATUSES = [RunStatus.COMPLETE.toString(),
                                               RunStatus.FAILED.toString(),
                                               RunStatus.NEEDS_ATTENTION.toString(),
                                               RunStatus.TIME_OUT.toString()];

// All possible statuses for which a Run is editable.
export const EDITABLE_RUN_STATUSES = [RunStatus.READY.toString(), RunStatus.TIME_OUT.toString(), RunStatus.STOPPED.toString(),
RunStatus.COMPLETE.toString(), RunStatus.FAILED.toString(), RunStatus.PLANNING.toString()];

// All possible statuses for which a Run is archivable.
export const ARCHIVABLE_RUN_STATUSES = [RunStatus.STOPPED.toString(), RunStatus.FAILED.toString(), RunStatus.UPLOAD_FAILED.toString(),
RunStatus.COMPLETE.toString(), RunStatus.NEEDS_ATTENTION.toString()];

// All possible statuses for which a Run is Trashable.
export const TrashableRunStatuses = [RunStatus.COMPLETE.toString(), RunStatus.FAILED.toString(), RunStatus.STOPPED.toString(),
RunStatus.NEEDS_ATTENTION.toString(), RunStatus.UPLOAD_FAILED.toString(), RunStatus.TIME_OUT.toString()];

export const NON_SUCCESS_RUN_STATES = [RunStatus.FAILED.toString(), RunStatus.STOPPED.toString(),
    RunStatus.NEEDS_ATTENTION.toString(), RunStatus.UPLOAD_FAILED.toString(), RunStatus.TIME_OUT.toString()];
// All possible statuses for which a Run is NOT Downloadable
export const NON_DOWNLOADABLE_RUN_STATUSES = [RunStatus.UPLOADING.toString()];
